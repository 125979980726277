import styles from './PostsHeroSide.module.css'
import PostCardUI from 'shared-components/posts/PostCardUI'
import stylesCommon from './PostsHeroMain.PostsHeroSide.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import AppImage from 'shared-components/ui/AppImage'
import Linked from 'shared-components/service/Linked'

interface PostsHeroSideProps extends BasicStyledComponent {
  post: DSPostShortWithAuthor
}

const PostsHeroSide: React.VFC<PostsHeroSideProps> = ({ className, post }) => (
  <Linked className={clsx(styles.container, className)} {...post.pLink}>
    <div>
      <div className={styles.title}>{post.title}</div>
      {post.author ? (
        <div className={clsx(stylesCommon.authorText, styles.author)}>{post.author.name}</div>
      ) : null}
    </div>
    {post.image ? (
      <AppImage
        className={styles.image}
        image={post.image}
        sizes={[{ bp: 'lg', val: 280 }, 70]}
        layout="raw"
      />
    ) : null}
    <div className={styles.uiHolder}>
      <PostCardUI post={post} />
    </div>
  </Linked>
)

export default PostsHeroSide
