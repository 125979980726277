import styles from './CBMissed.module.css'
import styleUtils from 'shared-components/styles/util.module.css'
import { useCallback } from 'react'
import { getds } from 'ds'
import { CommonDataContext } from 'shared-components/contexts/CommonDataContext'
import DoubleListing from 'shared-components/posts/DoubleListing'
import clsx from 'clsx'
import { DSCBMissed } from 'shared-definitions/types'

interface CBMissedProps {
  data: DSCBMissed
}

const CBMissed: React.VFC<CBMissedProps> = ({ data: input }) => {
  const { ts } = CommonDataContext.useContainer()
  const pageLoader = useCallback(
    async (p: number) => {
      const dataSource = await getds({ ts })
      const { data } = await dataSource.getPage404(
        { page: p, perPage: input.hotPosts.perPage },
        { page: p, perPage: input.posts.perPage }
      )
      return [
        {
          left: data.hotPosts.items,
          right: data.posts.items,
        },
      ]
    },
    [ts, input]
  )

  return (
    <div className={styles.container}>
      <h3 className={clsx(styleUtils.pageBodyTitle, styles.title)}>{input.title}</h3>
      <h3 className={clsx(styleUtils.pageBodyTitle, styles.subtitle)}>{input.subtitle}</h3>
      <DoubleListing
        className={styles.list}
        initial={{ left: input.hotPosts.items, right: input.posts.items }}
        loader={pageLoader}
      />
    </div>
  )
}

export default CBMissed
