import { MapDiscriminatedComponent } from 'shared-definitions/types'
import { componentMapper } from 'shared-code/component'
import { CBAdsControlContext } from 'shared-components/contexts/CBAdsControlContext'
import CBMissed from 'home/CBMissed'
import CBFeatured from 'home/CBFeatured'
import CBComponent from 'home/CBComponent'
import { DSContentBlockAA } from 'types.aa.ds'

const map: MapDiscriminatedComponent<DSContentBlockAA, 'resource'> = {
  'cb-featured': CBFeatured,
  'cb-missed': CBMissed,
  'cb-component': args => (
    <CBAdsControlContext.Provider>
      <CBComponent {...args} />
    </CBAdsControlContext.Provider>
  ),
}

const ContentBlock = componentMapper(map)

export default ContentBlock
