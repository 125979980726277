import clsx from 'clsx'

import AppImage from 'shared-components/ui/AppImage'
import Linked from 'shared-components/service/Linked'
import PostCardUI from 'shared-components/posts/PostCardUI'
import type { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'

import styleUtils from 'shared-components/styles/util.module.css'
import stylesCommon from './PostsHeroMain.PostsHeroSide.module.css'
import styles from './PostsHeroMain.module.css'

interface PostHeroMainProps extends BasicStyledComponent {
  post: DSPostShortWithAuthor
}

const PostHeroMain: React.VFC<PostHeroMainProps> = ({ post, className }) => (
  <Linked className={clsx(styles.container, styleUtils.mobileFull, className)} {...post.pLink}>
    {post.image ? (
      <AppImage
        preload
        layout="raw"
        className={styles.image}
        sizes={[{ bp: 'lg', val: 620 }, '100vw']}
        image={post.image}
      />
    ) : null}
    <div className={styles.title}>
      <span className={styles.titleText}>{post.title}</span>
    </div>
    {post.excerpt ? <div className={styles.description}>{post.excerpt}</div> : null}
    {post.sponsored && post.sponsor ? (
      <div className={clsx(styles.author, styles.authorText, stylesCommon.authorText)}>
        {post.sponsor}
      </div>
    ) : null}
    {!(post.sponsored && post.sponsor) && post.author ? (
      <div className={clsx(styles.author, styles.authorText, stylesCommon.authorText)}>
        {post.author.name}
      </div>
    ) : null}
    <PostCardUI post={post} />
  </Linked>
)

export default PostHeroMain
