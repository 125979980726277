import styles from './ComponentTitle.module.css'
import ArrowRight from 'shared-svg/ArrowRight.svg'
import Linked from 'shared-components/service/Linked'
import styleUtils from 'shared-components/styles/util.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSComponentHeader } from 'shared-definitions/types'

interface ComponentTitleProps extends BasicStyledComponent {
  header: DSComponentHeader
}

const ComponentTitle: React.VFC<ComponentTitleProps> = ({ className, header }) => {
  const titleClass = clsx(className, styleUtils.pageBodyTitle, styles.title)

  if (!header.title) {
    return null
  }

  return header.pLink ? (
    <Linked className={titleClass} {...header.pLink}>
      {header.title}
      <ArrowRight className={styles.icon} />
    </Linked>
  ) : (
    <span className={titleClass}>{header.title}</span>
  )
}

export default ComponentTitle
