import clsx from 'clsx'
import { PlaywireContext } from 'shared-components/contexts/PlaywireContext'
import { PostContext } from 'shared-components/contexts/PostContext'
import { BasicStyledComponent } from 'shared-definitions/types'
import styles from './TopBanner.module.css'

const TopBannerInContent: React.VFC<BasicStyledComponent> = ({ className }) => {
  const { disabledAds } = PostContext.useContainer()
  const { enabled } = PlaywireContext.useContainer()
  if (disabledAds) {
    return null
  }
  if (!enabled) {
    return null
  }
  return (
    <div className={clsx(styles.topLeaderboard, className)}>
      <div className="pw-incontent" />
    </div>
  )
}

export default TopBannerInContent
